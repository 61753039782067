import React from 'react'
import { styled } from 'fannypack'
import { useParams } from 'react-router-dom'
import { POWERED_BY_LOGO_URL } from '../constants'
import { getPrivacyLinkByStoreCode } from '../utils/project'

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center;
`
const Text = styled.div`
  margin-right: 0.2rem;
  color: #6d6e71;
  font-size: 0.875rem;
`
const Image = styled.img`
  height: 1rem;
`
const Privacy = styled.a`
  font-size: 0.875rem;
  color: #6d6e71;
  margin-top: 0.125rem;
  text-align: center;
  &:focus {
    outline: none;
  }
`
const Pipe = styled.div`
  height: 1.25rem;
  width: 1px;
  position: relative;
  margin-left: 0.4rem;
  margin-right: 0.5rem;
  border-left: 1px solid lightgray;
`

const PoweredBy = () => {
  const { storeCode } = useParams()
  const link = getPrivacyLinkByStoreCode(storeCode)
  return (
    <Container>
      <Text>Powered by</Text>
      <Image alt="powered-by-localz" src={POWERED_BY_LOGO_URL} />
      <Pipe />
      <Privacy href={link} target="_blank" rel="noopener noreferrer">
        Privacy
      </Privacy>
    </Container>
  )
}

export default PoweredBy
