import React, { useState, useRef } from 'react'
import { styled, Modal, Button, DialogModal, Spinner, Icon } from 'fannypack'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
// @ts-ignore
import CodeInput from 'react-code-input'
import * as sdk from '../../sdk'
import { sendSentryError } from '../../utils/sentry'
import { LOCAL_STORAGE_JWT, GRAPHQL_ERROR } from '../../constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Text = styled.div`
  color: #000000;
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 1rem;
`
const MessageContainer = styled.div`
  margin-top: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const Message = styled.div<{ success: boolean }>`
  color: ${(props) => (props.success ? '' : '#da291c')};
  font-size: 0.875rem;
  display: flex;
`

const StyledCloseIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`
const StyledButton = styled(Button)<{ alignSelf?: string }>`
  padding: 0;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  min-height: unset;
  width: 2rem;
  align-self: ${(props) => props.alignSelf};
  &:focus {
    box-shadow: none;
  }
  &:hover {
    background-color: transparent;
    &:active {
      background-color: transparent;
    }
  }
`
const DefaultErrorMessage = 'The code is invalid, please try again'
const RefundButton = () => {
  const [refundPin, setRefundPin] = useState<string>('')
  const [isRefunding, setIsRefunding] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const { orderNumber, storeCode } = useParams<{
    orderNumber: string
    storeCode: string
  }>()
  const codeRef = useRef(undefined)
  const handleChange = async (pin: string, hide: Function) => {
    setRefundPin(pin)
    if (error) {
      setError('')
    }
    if (pin && pin.length === 5) {
      setIsRefunding(true)
      setDisabled(true)
      try {
        const res = await sdk.refundOrder({
          refundPin: pin.toUpperCase(),
          orderNumber,
          token: localStorage.getItem(LOCAL_STORAGE_JWT)!
        })
        if (get(res, 'data.refundOrder.success')) {
          setIsRefunding(false)
          setSuccess(true)
          setTimeout(async () => {
            hide()
          }, 1000)
        } else {
          clearInput()
          setIsRefunding(false)
          setError(DefaultErrorMessage)
          setDisabled(false)
        }
      } catch (e) {
        const err = get(e, 'message', '')
        if (err.startsWith(GRAPHQL_ERROR.vendor)) {
          setError(`${err.replace(GRAPHQL_ERROR.prefix, '')}.`)
        } else {
          setError(DefaultErrorMessage)
        }
        clearInput()
        setIsRefunding(false)
        setDisabled(false)
        sendSentryError(e, {
          desc: 'Failed to refund order',
          refundPin,
          orderNumber,
          storeCode
        })
      }
    }
  }

  const handleClick = () => {
    clearInput()
    setError('')
    setSuccess(false)
    setIsRefunding(false)
    setDisabled(false)
  }

  const clearInput = () => {
    // https://github.com/40818419/react-code-input/issues/69#issuecomment-506657412
    // @ts-ignore
    codeRef.current.state.input[0] = ''
    // @ts-ignore
    codeRef.current.state.input[1] = ''
    // @ts-ignore
    codeRef.current.state.input[2] = ''
    // @ts-ignore
    codeRef.current.state.input[3] = ''
  }
  return (
    <Modal.Container>
      {(modal) => (
        <>
          <Button
            color="#fff"
            palette="secondary"
            use={Modal.Show}
            onClick={handleClick}
            {...modal}
          >
            Refund order
          </Button>
          <DialogModal {...modal} hideOnClickOutside={false}>
            <Container>
              <StyledButton
                // @ts-ignore
                onClick={modal.hide}
                disabled={disabled}
              >
                <StyledCloseIcon icon="solid-times" color="#757575" />
              </StyledButton>
              <Text>Enter the refund code</Text>
              <CodeInput
                name="RefundCodeInput"
                //@ts-ignore
                ref={codeRef}
                inputMode="verbatim"
                type="text"
                fields={5}
                inputStyle={{
                  width: '3rem',
                  height: '3rem',
                  borderRadius: '0.125rem',
                  color: '#0D5257',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: '#0D5257',
                  textAlign: 'center',
                  margin: '0.8vw',
                  fontWeight: 'bold',
                  fontSize: '1.25rem',
                  textTransform: 'uppercase'
                }}
                //@ts-ignore
                onChange={(pin: string) => handleChange(pin, modal.hide)}
                disabled={isRefunding}
                value={refundPin}
              />
              <MessageContainer>
                {isRefunding && <Spinner />}
                {!isRefunding && (error || success) && (
                  <Message success={success}>
                    {success ? 'Code successful!' : error}
                  </Message>
                )}
              </MessageContainer>
            </Container>
          </DialogModal>
        </>
      )}
    </Modal.Container>
  )
}

export default RefundButton
