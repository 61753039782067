import React, { useState } from 'react'
import { styled, Button } from 'fannypack'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'

import CodeInput from 'react-code-input'
import PoweredBy from '../components/PoweredBy'
import { getProjectIdByStoreCode } from '../utils/project'
import { sendSentryWarning } from '../utils/sentry'
import { LOCAL_STORAGE_JWT, GRAPHQL_ERROR } from '../constants'
import Header from '../components/Header'
import * as sdk from '../sdk'
import { changeSubscriptionToken } from '../sdk/client'
import collectEvent from '../utils/events'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.25rem 0.75rem 0 0.75rem;
`
const Title = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
`
const Text = styled.div`
  color: #757575;
  font-size: 1.25rem;
  text-align: center;
  padding: 2.25rem 1rem 1.5rem 1rem;
  @media only screen and (max-width: 320px) {
    padding: 1.25rem 1rem 0.5rem 1rem;
  }
`
const StyledButton = styled(Button)`
  padding: 0.75rem;
  margin: 0.75rem 0.75rem 0 0.75rem;
  border: none;
  color: #fff;
`
const Warning = styled.div`
  font-size: 0.75rem;
  color: #da291c;
  width: 100%;
  text-align: center;
`

const Login = () => {
  const history = useHistory()
  const [storeCode, setStoreCode] = useState<string>('')
  const [bookingCode, setBookingCode] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleClick = async () => {
    try {
      const res = await sdk.login({
        storeCode,
        bookingCode,
        projectId: getProjectIdByStoreCode(storeCode)!
      })
      const token = get(res, 'data.loginBooking.token')
      localStorage.setItem(LOCAL_STORAGE_JWT, token)
      changeSubscriptionToken(token)

      collectEvent({
        storeCode,
        eventLabel: 'LOGIN'
      })

      history.push(`/${storeCode}`)
    } catch (e) {
      const err = get(e, 'message', '')
      if (err.startsWith(GRAPHQL_ERROR.login)) {
        setError(`${err.replace(GRAPHQL_ERROR.prefix, '')}.`)
      } else {
        setError('Failed to login, please try again')
      }
      sendSentryWarning(e, {
        desc: 'Failed to login',
        storeCode,
        bookingCode,
        projectId: getProjectIdByStoreCode(storeCode)
      })
    }
  }

  const handleChangeStoreCode = (code: string) => {
    if (error) {
      setError('')
    }
    setStoreCode(code.toUpperCase())
  }

  const handleChangeBookingCode = (code: string) => {
    if (error) {
      setError('')
    }
    setBookingCode(code.toUpperCase())
  }
  return (
    <Container>
      <Header />
      <Content>
        <Title>Login to your booking</Title>
        <Text>Enter the event code</Text>

        <CodeInput
          type="text"
          inputMode="numeric"
          name="LoginStoreCodeInput"
          fields={4}
          inputStyle={{
            width: '3rem',
            height: '3rem',
            borderRadius: '0.125rem',
            color: '#0D5257',
            border: '1px solid #0D5257',
            textAlign: 'center',
            margin: '0 0.5rem 0 0.5rem',
            fontWeight: 'bold',
            fontSize: '1.25rem'
          }}
          // whitelisting is not working
          // blacklists everything that is not the keycodes of 0-9
          filterKeyCodes={Array.from(Array(300).keys()).filter(
            (k) => ![48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(k)
          )}
          filterChars={['!', '@', '#', '$', '%', '^', '&', '*', '(', ')']}
          value={storeCode}
          onChange={handleChangeStoreCode}
        />
        <Text>Enter your unique booking code</Text>
        <CodeInput
          name="LoginBookingCodeInput"
          inputMode="verbatim"
          type="text"
          fields={4}
          inputStyle={{
            width: '3rem',
            height: '3rem',
            borderRadius: '0.125rem',
            color: '#0D5257',
            border: '1px solid #0D5257',
            textAlign: 'center',
            margin: '0 0.5rem',
            fontWeight: 'bold',
            fontSize: '1.25rem',
            textTransform: 'uppercase'
          }}
          onChange={handleChangeBookingCode}
          value={bookingCode}
          // eslint-disable-next-line
          autoFocus={false}
        />
      </Content>
      <Warning>{error}</Warning>
      <StyledButton
        palette="secondary"
        onClick={handleClick}
        disabled={
          !storeCode ||
          storeCode.length !== 4 ||
          !bookingCode ||
          bookingCode.length !== 4
        }
      >
        Log in
      </StyledButton>
      <PoweredBy />
    </Container>
  )
}

export default Login
